/* eslint-disable */
import * as z from 'zod'
import { camel, snake } from 'radash'

import {
  Struct,
  Entity,
  Meta,
  recase,
  CasingFunction,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { BundleLoader, GazelleEntityFactory, ValueOf } from '@apsys/gazelle'
import { ReffedEntity as ReffableEntity, WithRef } from '@apsys/gazelle'

export const meta: Meta = {
  name: 'Vixen Core',
  moduleName: 'vixen-core',
  version: '0.10.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// --- named ---
export interface NamedSchema {
  name: Record<string, string>
}

export interface INamed {
  name: Record<string, string>
}

// --- styled-named ---
export interface StyledNamedSchema extends NamedSchema {
  'styled-name': Record<string, string>
}

export interface IStyledNamed extends INamed {
  styledName: Record<string, string>
}

// --- action-source ---
export interface ActionSourceSchema {}

export interface IActionSource {}

// --- routable ---
export interface RoutableSchema extends ActionSourceSchema {}

export interface IRoutable extends IActionSource {}

// --- visitable ---
export interface VisitableSchema extends ActionSourceSchema {}

export interface IVisitable extends IActionSource {}

// --- subscribable ---
export interface SubscribableSchema extends ActionSourceSchema {}

export interface ISubscribable extends IActionSource {}

// --- playable ---
export interface PlayableSchema extends ActionSourceSchema {}

export interface IPlayable extends IActionSource {}

// --- content ---
export interface ContentSchema {}

export interface IContent {}

// === structs ===
// === entities ===
export type INTERFACES = {
  named: INamed
  'styled-named': IStyledNamed
  'action-source': IActionSource
  routable: IRoutable
  visitable: IVisitable
  subscribable: ISubscribable
  playable: IPlayable
  content: IContent
}

export const ENTITIES = {}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

export class Loader extends BundleLoader {
  static readonly ENTITIES = ENTITIES
  static readonly schemaVersion = '0.10.0'

  get<K extends keyof ENTITIES>(ref: {
    typename: K
    id: string
  }): ENTITIES[K] | undefined
  get<T extends ValueOf<ENTITIES> | ValueOf<INTERFACES> | Entity<any>>(
    ref: GazelleRef<T>,
  ): (T extends Entity<any> ? T : ReffableEntity<T> & T) | undefined
  get(ref: any) {
    return super.get(ref)
  }

  getAll<K extends keyof ENTITIES>(typename: K): Array<ENTITIES[K]>
  getAll<T extends ValueOf<ENTITIES>>(klass: GazelleEntityFactory<T>): Array<T>
  getAll(klass: any) {
    return super.getAll(klass)
  }

  getSingleton<K extends keyof ENTITIES>(typename: K): ENTITIES[K] | undefined
  getSingleton<T extends ValueOf<ENTITIES>>(
    klass: GazelleEntityFactory<T>,
  ): T | undefined
  getSingleton(klass: any) {
    return super.getSingleton(klass)
  }
}
