import { openDB } from 'idb'
import type { DBSchema, IDBPDatabase } from 'idb'

import { debugLog } from '../utils/debug'

const DB_NAME = 'CachingIdDB' as const
/** Bump the schema version to force a complete rebuild of the database */
const DB_SCHEMA_VERSION = 1 as const
const STORE_NAME = 'ids' as const

const MAGIC_KEY = 'cachingIDs' as const

/** This is the shape of the database */
interface DBType extends DBSchema {
  [STORE_NAME]: {
    key: typeof MAGIC_KEY
    value: {
      key: typeof MAGIC_KEY
      value: string[]
    }
  }
}

/**
 * A queue for Komodo events to be added when the app is offline.
 *
 * Couldn't use the `Queue` class workbox-background-sync provides as it
 * doesn't work consistently across app refreshes and new tabs.
 */
export class CachingAssetsDB {
  /**
   * A promise to open the database.
   *
   * Each call makes a separate request to open the database. This is to avoid
   * the issue in APM-1772, where the DB can be closed.
   */
  private get db(): Promise<IDBPDatabase<DBType>> {
    return openDB<DBType>(DB_NAME, DB_SCHEMA_VERSION, {
      upgrade: (db, oldVersion, newVersion) => {
        debugLog(DB_NAME, `upgrade needed: ${oldVersion} -> ${newVersion}`)

        this.upgradeDB(db)
      },
    })
  }

  private upgradeDB(db: IDBPDatabase<DBType>): void {
    try {
      db.deleteObjectStore(STORE_NAME)
    } catch {}

    db.createObjectStore(STORE_NAME, { keyPath: 'key' })
  }

  public async storeCachingIDs(ids: string[]): Promise<void> {
    const store = (await this.db)
      .transaction(STORE_NAME, 'readwrite')
      .objectStore(STORE_NAME)

    await store.put({ key: 'cachingIDs', value: ids })
  }

  public async retrieveCachingIDs(): Promise<string[]> {
    const store = (await this.db)
      .transaction(STORE_NAME, 'readonly')
      .objectStore(STORE_NAME)

    const entry = await store.get(MAGIC_KEY)
    return entry?.value ?? []
  }

  /**
   * There's only one key so you don't need to clear the store if you're just
   * writing in a new value.
   */
  public async clearCachingIDsStore(): Promise<void> {
    const store = (await this.db)
      .transaction(STORE_NAME, 'readwrite')
      .objectStore(STORE_NAME)

    await store.clear()
  }
}
