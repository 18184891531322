import { z } from 'zod'

export const AssetSchema = z.object({
  ref: z.object({
    typename: z.string(),
    id: z.string(),
  }),
  url: z.string(),
  contentType: z.string(),
  language: z.string().optional(),
  copyright: z.string().optional(),
  distributionControl: z.enum(['unrestricted', 'onsite', 'admin-only']),
  externalId: z.string().optional(),
})

export const CacheAssetEventSchema = z.object({
  type: z.literal('CACHE_ASSETS'),
  /**
   * The ids of all related entities that should be cached
   */
  cachingIds: z.array(z.string()),
  assets: z.array(AssetSchema),
})

export const WorkerEventSchema = z.discriminatedUnion('type', [
  CacheAssetEventSchema,
])

export type CacheAssetsEvent = z.infer<typeof CacheAssetEventSchema>

export type ToWorkerEvent = z.infer<typeof WorkerEventSchema>

export type WorkerEventMap = {
  [K in ToWorkerEvent['type']]: Extract<ToWorkerEvent, { type: K }>
}

export enum WorkerFetchResultType {
  Success = 'SUCCESS',
  Error = 'ERROR',
  TerminalError = 'TERMINAL_ERROR',
}

export type WorkerEventResponse<TResult = unknown> = {
  status: WorkerFetchResultType
  result?: TResult
  error?: unknown
}

export type ToWorkerEventHandlerMap = {
  [K in keyof WorkerEventMap]: (event: WorkerEventMap[K]) => Promise<void>
}
