export enum SEARCH_PARAMS {
  /** Override es-config (for debugging) */
  ES_CONFIG = 'es-config',

  BUNDLE_AUDIENCE = 'bundle',
}

export enum LOCAL_STORAGE {
  /** The currently chosen experience */
  EXPERIENCE = 'EXPERIENCE',
  /** The currently chosen theme variant (light/dark) */
  THEME_VARIANT = 'THEME_VARIANT',
  /** The device ID */
  DEVICE_ID = 'DEVICE_ID',
  /** The manifest ref */
  EVENT_MANIFEST = 'EVENT_MANIFEST',
}
